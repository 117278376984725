.preloader-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.70);
  display: none;

  .preloader {
    background: $preloader-url no-repeat center center;
    background-size: contain;
    width: 70px;
    height: 70px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}



#goto-contact {
  color: #fff;
  background: $primary;
  padding: 60px 0 60px 0;
  text-align: center;

  .lead {
    color: #fff;
    font-weight: bold;
    font-size: 24px;

    @media screen and(max-width: 320px) {
      font-size: 1.35em;
    }
  }

  .link {
    margin: 28px auto 0;
    padding: 11px 0 8px;
    width: 180px;
    color: $primary;
    background-color: #fff;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    font-size: 18px;
    line-height: 30px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .lead, .link {
    display: block;
  }

}

.go-to-prev {
  //padding: 30px 0 46px 0;
  //height: 170px;
  color: #666666;
  //background: $primary;
  text-align: center;

  .link {
    .arrow {
      position: absolute;
      top: 0;
      height: 30px;
      width: 170px;
      background: url('https://s3.amazonaws.com/geopagos/images/bajio/landing/icon-arrow-up.png') center center no-repeat;
    }
  }
}

#goto-top {
  padding: 30px 0 46px 0;
  //height: 170px;
  color: #666666;
  background: #FFFFFF;
  text-align: center;

  .link {
    cursor: pointer;
    position: relative;
    display: inline-block;
    height: 47px;
    margin: 0 auto;

    .arrow {
      position: absolute;
      top: 0;
      height: 30px;
      width: 170px;
      background: url('https://s3.amazonaws.com/geopagos/images/bajio/landing/icon-arrow-up.png') center center no-repeat;
    }

    .arrow,
    .text {
      text-align: center;
      padding-top: 0;
      font-size: 17px;
      text-transform: uppercase;
      font-weight: 400;
      color: #666666;
      display: block;
      -webkit-transition: 0.15s;
      -moz-transition: 0.15s;
      -ms-transition: 0.15s;
      -o-transition: 0.15s;
      transition: 0.15s;
    }

    .text {
      padding-top: 40px;
      font-size: 18px;
      color: #808080;
    }

  }

  .link:hover .arrow, .link:focus .arrow {
    top: -10px;
  }

}
@import "bootstrap/functions";
@import "bootstrap/variables";

$theme-colors: (
  primary: $blue,
  secondary: $gray-600,
  success: $green,
  info: $cyan,
  warning: $yellow,
  danger: $red,
  light: $gray-100,
  dark: $gray-800
);


/* PREVIOUS */


$processor: 'bajio';

$aws-url: 'https://s3.amazonaws.com/geopagos/images/';

$img-host-url: $aws-url + $processor + '/landing/';

/*
 * Fonts
 */
$global-font-family : "36px/1.3em 'Montserrat', Arial, sans-serif";

$brand-color: #602daa;
$brand-color-hover: #8441ea;
$brand-color-dark: #EEEEEE;

$primary: $brand-color;
$primary-dark: $brand-color-dark;
$secondary: #1D1D1B;

$section-gradient: linear-gradient(#FFFFFF 0%, #FFFFFF 23.52%, #F4F4F4 57.68%, #EFEFEF 100%);
$header-gradient : linear-gradient(to bottom, #763cc5, #773cc6 24%, #6c32bc 49%, #511aa0 77%, #320977);

/*
 *  ICON URLS
 */
$icon-star-url: $img-host-url + 'icons/icon-star.png';
$icon-catalog-url: $img-host-url + 'icons/icon-catalog.png';
$icon-arrow-left-url: $img-host-url + 'icons/icon-arrow-left.png';
$icon-clock-url: $img-host-url + 'icons/icon-clock.png';
$icon-staff-url: $img-host-url + 'icons/icon-staff.png';
$icon-dashboard-url: $img-host-url + 'icons/icon-dashboard.png';
$icon-google-play-url: url($img-host-url + 'playstore.png');
$icon-app-store-url: url($img-host-url + 'appstore.png');
$icon-envelope: url($img-host-url + 'icon-email.png');

$sm-icons-list-hover-url: url($img-host-url + 'resume-list-icons-hover.png');
$sm-icons-list-url: url($img-host-url + 'resume-list-icons.png');

$hiw-sprites-url: $img-host-url + 'v2/hiw-sprites.png';
$hiw-bullet-url: $img-host-url + 'hiw-bullet.png';

$preloader-url: url($img-host-url + 'preloader.gif');

/*
 * DEVICES URLS
 */
$prepaid-device-url: url($img-host-url + 'toditocash.png');
$staff-device-url: url($img-host-url + 'v2/staff.png');
$hero-device-url: url($img-host-url + 'v2/hero.png');
$clients-device-url: url($img-host-url + 'v2/clients.png');
$catalog-device-url: url($img-host-url + 'v2/catalog.png');
$history-device-url: url($img-host-url + 'v2/sales-history.png');
$resume-device-url: url($img-host-url + 'v2/device-resume.png');

/*
 * BRANDS URLS
 */
$brand-logo-url: url($img-host-url + 'brand.png');
$dashboard-image-url: url($img-host-url + 'v2/dashboard.png');
$powered-by-image : url($aws-url + $processor  + '/landing/powered.png');


@mixin autoresizable-bg-img {
  //background-image: url(http://leavalleyheritagealliance.org.uk/dist/img/4.svg);
  background-repeat: no-repeat;
  background-position: 100% top;
  background-attachment: fixed;
}
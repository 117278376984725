@import 'fonts';
@import 'variables';
@import 'logos';
@import 'bootstrap/bootstrap';
@import 'normalize';
/*
* SECTIONS
*/
@import 'sections/catalog';
@import 'sections/clients';
@import 'sections/hero';
@import 'sections/panel';
@import 'sections/resume';
@import 'sections/staff';
@import 'sections/goto';
@import 'sections/how-it-works';
@import 'sections/sales-history';
/*
 * MODALS
 */
@import 'modals/contact';
/*
* MAIN
*/
@import 'icons';
@import 'preloader';
@import 'header';
@import 'footer';
@import 'popup';
@import 'main';
@import 'responsive-menu';
.popup {
  display: none;
  position: fixed !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.25);
}

.popup .bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
}

.popup .modal-dialog {
  position: absolute;
  z-index: 99;
  top: 50px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  width: 440px !important;
}

.popup .modal-dialog .modal-content {
  position: relative;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 3px;
  border: 0;
}

.popup .modal-dialog .modal-content .modal-header {
  min-height: 0;
  padding: 0;
  border: 0;
}

.popup .modal-dialog .modal-content .modal-header .modal-title {
  display: none;
}

.popup .modal-dialog .modal-content .modal-header .close.close-video {
  left: 950px;
  top: 49px;
  background: url('https://s3.amazonaws.com/geopagos/images/bajio/landing/icon-close_video.png') center center no-repeat;
}

.popup .modal-dialog .modal-content .modal-header .close {
  position: absolute;
  z-index: 9999;
  right: 0;
  display: block;
  color: #555;
  width: 20px;
  height: 20px;
  margin: 25px;
  background: url('https://s3.amazonaws.com/geopagos/images/bajio/landing/icon-close.png') center center no-repeat;
  opacity: 1;
  -webkit-transition: .25s;
  -moz-transition: .25s;
  -ms-transition: .25s;
  -o-transition: .25s;
  transition: .25s;
}

.popup .modal-dialog .modal-content .modal-header .close:hover, .popup .modal-dialog .modal-content .modal-header .close:focus {
  opacity: .5;
}

.popup .modal-dialog .modal-content .modal-body {
  padding: 45px;
}

.popup .modal-dialog .modal-content .modal-body a {
  color: #602daa;
  text-decoration: underline !important;
}

.popup .modal-dialog .modal-content .modal-body h2 {
  margin-bottom: 5px;
}

.popup .modal-dialog .modal-content .modal-body h3 {
  font-size: 22px;
  color: #602daa;
  margin-bottom: 30px;
  font-weight: 600;
}

.popup .modal-dialog .modal-content .modal-body p {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 30px;
}

.popup .modal-dialog .modal-content .modal-body p.substring {
  font-size: 14px;
  line-height: 22px;
}

.popup .modal-dialog .modal-content .modal-body form .form-group .form-control {
  font: 18px/1.5em 'Montserrat', Arial;
  height: auto;
  border: 0;
  border-bottom: 2px solid #dddddd;
  box-shadow: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.popup .modal-dialog .modal-content .modal-body form .form-group.has-error .form-control {
  border-color: #cc1410 !important;
}

.popup .modal-dialog .modal-content .modal-body .btn {
  width: 100%;
  height: 55px;
  font: 20px/20px 'Montserrat', Arial;
  text-align: center;
  color: #fafafa;
  background-color: #602daa;
  padding: 10px 0 7px;
  font-weight: 600;
  -webkit-transition: 0.25s;
  -moz-transition: 0.25s;
  -ms-transition: 0.25s;
  -o-transition: 0.25s;
  transition: 0.25s;
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  border-radius: 4px !important;
}

.popup .modal-dialog .modal-content .modal-body form .btn:hover, .popup .modal-dialog .modal-content .modal-body form .btn:focus {
  background-color: #602daa;
}

.popup .modal-dialog .modal-content .modal-body .form-response {
  display: block;
  border: 1px solid #dddddd;
  margin-top: 10px;
  padding: 15px;
  font: 18px/1.25em;
  color: #555555;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  font-weight: 600;
}

.popup .modal-dialog .modal-content .modal-body .link {
  display: block;
  width: 100%;
  font: 20px/1.5em 'Montserrat', Arial;
  text-align: center;
  color: #fafafa;
  background-color: #555555;
  padding: 7px 0;
  margin-bottom: 15px;
  -webkit-transition: 0.25s;
  -moz-transition: 0.25s;
  -ms-transition: 0.25s;
  -o-transition: 0.25s;
  transition: 0.25s;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  border-radius: 5px !important;
}

.popup .modal-dialog .modal-content .modal-body .link:hover, .popup .modal-dialog .modal-content .modal-body .link:focus {
  background-color: #666;
}

.popup .modal-dialog .modal-content .modal-body .link.margin-b {
  margin-bottom: 45px;
}

.popup .modal-dialog .modal-content .modal-body .link.blue {
  background-color: #763cc5;
}

.popup .modal-dialog .modal-content .modal-body .link.blue:hover, .popup .modal-dialog .modal-content .modal-body .link.blue:focus {
  background-color: #602daa;
}
@mixin brand-logo() {
  width: 165.52px;
  height: 80px;
  //background: $brand-logo-url center center no-repeat;
}

#google-play {
  background: $icon-google-play-url center center no-repeat;
  width: 151px;
  height: 45px;
}

#app-store {
  background: $icon-app-store-url center center no-repeat;
  width: 151px;
  height: 45px;
}
#panel {
  .device {
    margin-left: -200px;
    height: 600px;
    width: 685px;
    background: $dashboard-image-url center no-repeat;
    background-size: contain;

    @media screen and(min-width: 992px) {
      margin-left: -40%;
    }

    @media screen and(max-width: 1200px) {
      margin-left: -60%;
    }

    @media screen and(max-width: 992px) {
      margin-left: 0px;
      max-width: 100%;
    }
  }

  .desc {
    //width: 360px !important;

    .desc h3 {
      font: 19px/1em 'Montserrat', Arial, sans-serif;
      margin: 15px 0;
      font-weight: 600;
    }

    .desc p {
      margin-bottom: 35px;
    }

    @media screen and(max-width: 768px) {
      margin-left: -87px;
    }
  }

  hr {
    margin: 30px 0;
  }

  .brief {
    @media screen and(max-width: 576px) {
      padding: 2em;
    }
  }
}

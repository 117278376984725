input[type=checkbox] {
  /* Double-sized Checkboxes */
  position: relative;
  top: 2px;
  margin-right: 6px;
  padding: 10px;
  /*-ms-transform: scale(2);
  -moz-transform: scale(2);
  -webkit-transform: scale(2);
  -o-transform: scale(2);*/
}

textarea {
  resize: vertical;
}

html {
  overflow-x: hidden;
}

html, body, li, ul, p {
  margin: 0;
  padding: 0;
  font-family: Gotham, serif;
}

img {
  border: 0;
  display: block;
}

a, button {
  text-decoration: none !important;
  outline: none !important;
}

a, i {
  -webkit-transition: 0.20s;
  -moz-transition: 0.20s;
  -ms-transition: 0.20s;
  -o-transition: 0.20s;
  transition: 0.20s;
}

a:hover {
  color: #000000;
}

ul {
  list-style: none;
}

body, p {
  font-size: 15px;
  line-height: 1.6em;
  font-weight: 400;
  letter-spacing: 0;
  color: #666;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  text-rendering: optimizeLegibility;
  color: #555555;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

strong {
  font-weight: 600;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  overflow-x: hidden;
}

.line {
  display: block;
  margin-bottom: 20px;
  width: 100%;
  height: 1px;
  border-color: #666;
  border-style: solid;
  border-width: 1px 0 0 0;
}

input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"], select.form-control {
  color: #333;
}

textarea:focus, input[type="text"]:focus, input[type="password"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="date"]:focus, input[type="month"]:focus, input[type="time"]:focus, input[type="week"]:focus, input[type="number"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="color"]:focus, .uneditable-input:focus {
  border-color: #602daa;
  box-shadow: 0 1px 1px rgba(206, 186, 154, 0.5) inset, 0 0 8px rgba(171, 143, 91, 0.75);
  outline: 0 none;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -moz-appearance: none;
  -webkit-appearance: none;
  margin: 0;
}

.error-message {
  margin: 20px 0 !important;
  color: red;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
}

.show-password {
  position: absolute;
  top: 8px;
  right: 0;
  cursor: pointer;
  color: #602daa;
  text-decoration: none;
}

.popup .modal-dialog.popup-video {
  position: absolute;
  z-index: 99;
  top: 50px;
  left: 50%;
  margin-left: -509px;
  width: 0 !important;
}

.popup .modal-dialog.popup-video .modal-content {
  height: 0;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url('https://s3.amazonaws.com/geopagos/images/bajio/landing/dropdown_arrow.png') no-repeat;
  background-position: 380px 10px;
  text-indent: 0.01px;
  text-overflow: "";
  outline: none;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.divider {
  display: block;
  width: 100%;
  height: 5px;
  background: url('https://s3.amazonaws.com/geopagos/images/bajio/landing/divider.png') left center repeat-x;
  margin: 30px 0;
}

.hidden {
  display: none;
}

.no-padding {
  padding: 0;
}

.clearfix {
  clear: both;
}

.container-non-responsive {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  max-width: 950px;
}

.text-shadow {
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.75);
}

.form-group {
  position: relative;
}

.popup .modal-dialog .modal-content .modal-body .input-divider {
  display: inline-block;
  padding: 0 5px;
  width: 10%;
  height: 41px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}

.button-slide {
  background-color: #fff;
  color: #602daa;
  padding: 18px 30px 15px;
  border-radius: 4px;
  font-weight: 500;
  position: absolute;
  font-size: 16px;
}

.button-slide:hover,
.button-slide:focus {
  color: #ffffff;
  background-color: #602daa;
}

.section {
  width: 100%;
  position: relative;
  padding: 100px 0;
  //border-bottom: 1px solid #ddd;
  background: $section-gradient;

  .brief {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .title {
    margin: 0 0 97px 0;

    h1 {
      font-size: 40px;
      color: $primary;
      text-align: center;
      text-transform: uppercase;
    }

    p {
      font-size: 22px;
      color: #999;
      text-align: center;
      font-weight: 400;
    }
  }

  .desc {
    //max-width: 360px;
    text-align: justify;

    p {
      font-size: 18px;
      line-height: 26px;
      font-weight: 400;
      color: #1d1d1b;
    }

    h3 {
      margin-top: 6px;
      margin-bottom: 21px;
      font-size: 20px;
      font-weight: 600;
      color: $primary;
      text-transform: uppercase;
    }

    //h3, p {
    //  @media screen and(max-width: 768px) {
    //    text-align: center;
    //  }
    //}
  }
}

.device {
  width: 100%;
  background-position-x: center;
  background-position-y: center;
  background-size: contain;
  background-repeat: no-repeat;
}

#catalogo, #historial-ventas, #panel, #vendedores, #clientes, #prepaid, #resume {
  @media screen and(max-width: 768px) {
    text-align: center
  }
}

.bottom-right {
  position: fixed;
  bottom: 40px;
  right: 40px;
  height: 80px;
  background-color: #7638d0;
  width: 80px;
  padding: 15px 16px;
  font-size: 0.6em;
  line-height: 1.33;
  border-radius: 40px;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.3);

  i {
    font-size: 48px;
    color: white;
  }

  /**
   * En lugar de mostrar u ocultar el texto con display none/block
   * Hereda el color de fondo y le aplica color blanco en el hover
   */
  span {
    display: none;
  }

  &:hover {
    padding: 7px;
    background-color: #8441e4;

    span {
      display: block;
      color: white;
      margin-top: -12px;
    }
  }
}

#go-back-button {
  z-index: 9999;
}

.modal-footer {
  border: none;
}


/**
 * Helpers to hide or show depending on breakpoints
 */
.hide-x-576 {
  @media screen and (max-width: 576px) {
    display: none !important;
  }
}

.hide-n-576 {
  @media screen and (min-width: 576px) {
    display: none !important;
  }
}

.hide-x-768 {
  @media screen and (max-width: 768px) {
    display: none !important;
  }
}

.hide-n-768 {
  @media screen and (min-width: 768px) {
    display: none !important;
  }
}

.hide-x-992 {
  @media screen and (max-width: 992px) {
    display: none !important;
  }
}

#resume {
  background: $primary;
  height: 560px;
  position: relative;
  margin-bottom: 18em;

  @media screen and(max-width: 768px) {
    display: none;
  }

  @media screen and(min-width: 768px) {
    height: 42em;
  }

  @media screen and(min-width: 992px) {
    height: 36em;
  }

  //@media screen and(max-width: 992px) {
  //    height: 660px;
  //}

  //@media screen and(max-width: 768px) {
  //    height: 1200px;
  //}

  //@media screen and(max-width: 480px) {
  //    height: 1240px;
  //}

  .container {
    position: absolute;
    top: 104px;
    right: 0;
    left: 0;
    margin: auto;
    display:table-cell;

    p {
      font-size: 22px;
      margin-bottom: 42px;
      color: #fafafa;
      text-align: left;
    }

    .downloads {

      a {
        display: inline-block;
      }

      a:first-child {
        margin-right: 14px;
      }

      a:last-child {
        margin-left: 14px;

        @media screen and(max-width: 990px) {
          margin-left: 0;
        }
      }

    }

    .resume-list {
      position: relative;
      background-color: #fff;
      padding: 56px;
      border: solid 2px $primary;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;

      @media screen and (max-width: 992px) {
        padding: 56px 42px
      }

      @media screen and(min-width:576px) and (max-width: 768px) {
        padding: 56px 28px;
      }

      @media screen and (max-width: 544px) {
        padding: 28px;
      }

      ul li {
        margin-bottom: 17px;

        a {
          cursor: pointer;
          display: block;

          .icon-sm,
          .caption {
            display: table-cell;
            -webkit-transition: 0.25s;
            -moz-transition: 0.25s;
            -ms-transition: 0.25s;
            -o-transition: 0.25s;
            transition: 0.25s;
          }

          .caption {
            font-size: 16px;
            vertical-align: middle;
            color: #666;
            padding: 6px 0 6px 42px;
            font-weight: 600;
            text-transform: uppercase;
          }
        }

        a:hover .caption,
        a:focus .caption {
          color: $primary;
        }

        a:hover .icon-sm,
        a:focus .icon-sm {
          background-image: $sm-icons-list-hover-url;
        }

      }

      ul li:first-child {
        @media screen and(max-width: 992px) {
          display: none;
        }
      }

      ul li:last-of-type {
        margin-bottom: 0;
      }

    }

    .device {
        position: absolute;
        right: 0;
        //margin-top: 100px;
        //margin-right: -20px;
        //width: 275px;
        height: 576px;
        background: $resume-device-url right top no-repeat;

        @media screen and(max-width:768px) {
            background: $resume-device-url center top no-repeat;
            margin-top: 2.5rem !important;
        }
    }
  }
}

.back-to-section {
  margin: 4rem 0;

  p {
    font-size: 18px;
  }
}

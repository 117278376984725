#catalogo {
  .device {
    width: 100%;
    height: 615px;
    background-image: $catalog-device-url;
  }

  .desc {
    p {
      margin-bottom: 26px;
    }
  }

  .icon-lg {
    margin-top: unset;
  }
}


#clientes {

  .icon-lg {
    margin: 0px 0 21px;
  }

  .device {
    width: 100%;
    height: 620px;
    background-image: $clients-device-url;
  }

  .desc {
    p {
      margin-bottom: 35px;
    }
  }

  .row {
    @media screen and (min-width: 992px) {
      margin-left: 55px;
      margin-right: 55px;
    }
  }

}

#historial-ventas {
  .device {
    width: 100%;
    height: 600px;
    background: $history-device-url center center no-repeat;
  }

  h3 {
    font-weight: bold;
  }

  .brief {
    justify-content: unset;
  }
}



#contactModal {

  .modal-content {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    border: none;
  }

  .modal-header {
    border: none;
    justify-content: flex-end;
  }

  .modal-body {

    form {
      //padding-bottom: 100px;
      max-width: 420px;
      margin: auto;

      button {
        background: $primary;
        width: 100%;
        padding: 10px 0;
        color: #fff;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        font-weight: 600;

        &:hover {
          background: $brand-color-hover;
        }
      }

      input,
      select {
        height: 48px;
        padding: 7px 12px 6px;
      }

      .form-control:focus {
        border-color: $primary;
        outline: none;
      }

      input,
      select,
      textarea {
        font-size: 15px;
        border-radius: 2px;
      }

      textarea {
        padding: 14px 12px;
      }
    }

    .result {
      font-size: 16px;
      position: absolute;
      bottom: -20px;
      left: 0;
      right: 0;
    }
  }

  h3 {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 10px;

    @media screen and(max-width: 576px) {
      font-size: 33px;
    }
  }

  h3,
  p {
    text-align: center;
  }

  p {
    //margin-bottom: 60px;
    color: #999999;

    @media screen and(max-width: 576px) {
      font-size: 1.2em;
    }
  }

  .result-success {
    display: none;

    p {
      font-weight: bold;
    }
  }

  .result-error {
    visibility: hidden;
    margin-top: 27px;
    margin-bottom: 14px;

    p {
      padding-top: 6px;
      min-height: 32px;
      background-color: #ffeeef;
      color: #FF6671;
      font-weight: bold;
    }
  }

  .result-success {
    img, p {
      padding: 1em;
    }

    img {
      margin: auto;
    }

    p {
      color: $primary;
    }
  }

}

$powered-by-mt : 80px;

@mixin justify-content($position) {
    justify-content: $position;
}

@mixin media-justify-content($position) {
    @media screen and(max-width: 768px) {
        @include justify-content($position);
    }
}

#hero {
  margin-left: 33px;
  margin-right: 32px;

  @media screen and(max-width: 768px) {
    margin-left: unset;
    margin-right: unset;
  }

  .container {
    max-width: 1218px;
    padding: 40px 24px;
  }

  .container:first-child {
    padding: 0 24px;
  }

  .caption {
    margin-top: 80px;
    margin-left: 6px;

    @media screen and(max-width: 576px) {
      text-align: center;
      margin-top: 30px;
    }

    h1 {
      font: 36px/1.3em 'Montserrat', Arial, sans-serif;
      margin-bottom: 50px;
      color: #ffffff;
      padding-right: 3em;

      @media screen and(max-width: 992px) {
        padding-right: unset;
      }

      @media screen and(max-width: 576px) {
        font-size: 33px;
        text-align: left;
      }
    }

    h3 {
      font-size: 22px;
      line-height: 32px;
    }

    .register-button {
      color: $primary;
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
      padding: 18px 28px 14px 28px;
      margin-top: 40px;
      display: inline-block;
      @include border-radius(4px);
      border: 2px solid $primary;
      background: #fff;

      @media screen and(max-width: 576px) {
        width: 100%;
        margin-top: 0px;
        margin-bottom: 1em;
      }
    }

    .register-button:hover {
      background: #8441ea;
      color: #fff;
    }

  }

  .hero-device {
    min-height: 430px;
    width: 100%;
    background-image: $hero-device-url;
    background-position : right center;
    background-repeat: no-repeat;
    background-size: contain;

    @media screen and(max-width: 768px) {
      margin: 4em auto;
    }

    @media screen and(max-width: 576px) {
      min-height: 320px;
      background-position: center center;
    }
  }

  .logo-wrapper {
      margin: 15px 0;

      .hero-logo {
          padding: 0 10px;

          @media screen and (max-width: 576px) {
            justify-content: center;
          }
      }
  }

  .cursor-pointer{
    cursor: pointer;
  }

  // logo wrapper left
  .l-w-left:first-child {
    padding-right: 3em;
  }

  .logo-wrapper:first-child {
      @include justify-content(flex-start);

      @include media-justify-content(center);
  }

  .logo-wrapper:last-child {
      @include justify-content(flex-end);

      @include media-justify-content(center);
  }

  #googleplay {
    @media screen and (max-width: 576px) {
      justify-content: center;
    }
  }

}



.powered-by {
  background: $powered-by-image left center no-repeat;
  display: block;
  min-height: 25px;
  margin-top: $powered-by-mt;
  width:100%;

  @media screen and (max-width:880px) {
    margin-top: $powered-by-mt / 2;
  }

  @media screen and (max-width:400px) {
    margin-top: $powered-by-mt / 4;
  }
}

#hero-footer {
  padding: 100px 0;

  .lead {
    font-size: 28px;
    margin: 0 auto 45px auto;
    color: $primary;
    text-align: center;
    // max-width: 640px;
  }

  .readmore {
    cursor: pointer;
    position: relative;
    display: block;
    margin: 0 auto;
    width: 150px;

    .text {
      text-align: center;
      padding-top: 0;
      font-size: 18px;
      text-transform: uppercase;
      color: #666666;
    }

    .arrow {
      position: absolute;
      top: 30px;
      left: 0;
      right: 0;
      margin: auto;
      height: 30px;
      width: 38px;
      background: url('https://s3.amazonaws.com/geopagos/images/todito/landing/arrow-down.png') center center no-repeat;
    }

    .arrow,
    .text {
      display: block;
      -webkit-transition: 0.15s;
      -moz-transition: 0.15s;
      -ms-transition: 0.15s;
      -o-transition: 0.15s;
      transition: 0.15s;
    }
  }

  .readmore:hover .arrow,
  .readmore:focus .arrow {
    top: 40px;
  }
}

.top-wrapper {
  background: $header-gradient center center repeat;
}

footer {
  width: 100%;
  background-color: $primary-dark;

  .footer-link {
    color: $brand-color;
    text-decoration: underline !important;
  }

  .copy-link {
    color: #808080;
  }

  .copy {
    padding: 1.5em 1em 1.25em 1em;
    font-size: 14px;
    text-align: center;
    color: #808080;
  }

  .line {
    border-color: rgba(255, 255, 255, 0.6);
  }
}

#vendedores {
  .device {
    width: 100%;
    height: 615px;
    background: $staff-device-url center center no-repeat;
  }

  .desc {
    float: left;
  }

  .desc {
    //width: 350px;

    h3 {
      margin: 15px 0;
      font-weight: 600;
    }

    p {
      margin-bottom: 35px;
    }
  }

  hr {
    margin: 30px 0;
  }

  .col-md-5 {
    padding-top: 52px;

    @media screen and (min-width: 768px) {
      padding-top: 2em;
    }
  }
}


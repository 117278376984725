/*
 * LARGE ICONS
 */

.icon-lg {
  //width: 70px;
  height: 60px;
  margin: 60px 0 21px;
  background-position-x: left;
  background-position-y: center;
  background-repeat: no-repeat;

  @media screen and (max-width: 768px) {
    //background-position-x: center;
    width: 100%;
  }
}

.icon-lg-list {
  background-image: url($icon-catalog-url);
}

.icon-lg-arrow-left {
  background-image: url($icon-arrow-left-url);
}

.icon-lg-clock {
  background-image: url($icon-clock-url);
}

.icon-lg-star {
  background-image: url($icon-star-url);
}

.icon-lg-staff {
  background-image: url($icon-staff-url);
}

.icon-lg-dashboard {
  background-image: url($icon-dashboard-url);
}


/*
 * SMALL ICONS
 */

.icon-sm {
  width: 34px;
  height: 44px;
  background-image: $sm-icons-list-url;
}

.icon-sm-numbers {
  background-position: 0 0;
}

.icon-sm-prepaid {
  background-position: 0 305px;

  @media screen and (max-width: 992px) {
    background-position: 0 310px;
  }
}

.icon-sm-clock {
  background-position: 0 255px;

  @media screen and (min-width: 320px) and (max-width: 360px) {
    background-position: 0 260px;
  }

  @media screen and (min-width: 769px) and (max-width: 992px) {
    background-position: 0 260px;
  }
}

.icon-sm-list {
  background-position: 0 205px;

  @media screen and (max-width: 992px) {
    background-position: 0 210px;
  }
}

.icon-sm-staff {
  background-position: 0 100px;

  @media screen and (max-width: 992px) {
    background-position: 0 105px;
  }
}

.icon-sm-dashboard {
  background-position: 0 50px;

  @media screen and (min-width: 320px) and (max-width: 410px) {
    background-position: 0 58px;
  }

  @media screen and (min-width: 768px) and (max-width: 992px) {
    background-position: 0 60px;
  }
}

.icon-sm-star {
  background-position: 0 150px;

  @media screen and (max-width: 992px) {
    background-position: 0 155px;
  }
}
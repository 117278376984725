.header {
  padding: 18px 0;
  margin-left: 33px;
  margin-right: 32px;

  .container {
    max-width: 1218px;
  }

  .img-brand-logo {
    max-height: 138px;

    @media screen and(max-width: 992px) {
      max-height: 70%;
    }

    @media screen and(max-width:768px) {
      margin-right: auto !important;
      margin-left: auto !important;
    }

    @media screen and(max-width:576px) {
      margin-right: auto !important;
      margin-left: auto !important;
    }
  }

  .brand {
    @include brand-logo();
    float: left;
    margin-left: -20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .nav {
    .nav-item {
      color: #FFFFFF;
      padding: 12px;
      font-weight: bold;
      text-align: center;
      vertical-align: middle;
    }

    .btn-outline-danger {
      border-radius: 4px;
      border: 2px solid white;

      &:hover {
        color: white;
      }
    }

    a:last-child {
      margin-left: 3em;

      &:hover {
        background-color: #FFFFFF;
        color: $primary;
      }

      @media screen and (max-width: 992px) {
        margin-left: 2em;
      }

      @media screen and (max-width: 882px) {
        margin-left: 1em;
      }

      @media screen and (max-width: 780px) {
        margin-left: unset;
      }

      @media screen and (max-width: 768px) {
        margin-left: 3em;
      }

      @media screen and (max-width: 576px) {
        margin-left: 2em;
      }
    }
  }

  .nav ul {
    float: right;

    li {
      display: inline-block;
      margin: 0 10px;
      text-transform: uppercase;
      @include transition($transition-fade);

      a {
        display: block;
        padding: 14px 28px 10px;
        border: 2px solid transparent;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        color: #999999;

        //@media screen and(max-width: 576px) {
        //  padding: 9px 3px 9px;
        //}
      }

      a:focus {
        text-decoration: underline;
      }
    }

    li:last-child a {
      color: $primary;
      border-color: $primary;
      font-weight: 600;
    }

    li:last-child:hover {
      background: $primary;
      border-radius: 4px;

      a {
        color: #fff;
      }
    }
  }
}


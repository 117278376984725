.responsive-menu-container {
    display: none;
    padding: 3em 1em;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: auto;
    z-index: 999;
    background-color: #602daa;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.6);

    a {
        color: white;
    }

    .list-group-item {
        background-color: unset;
        border: none;
    }

    .btn-outline-none {
        border: none;
    }

    .cross {
        //padding: 7px 15px 0px 15px;
        font-size: 33px;
        //line-height: 65px;
    }

}

.hamburger, .cross {
    float: right;
    background: none;
    border: 0;
    font-weight: bold;
    cursor: pointer;
    outline: none;
    cursor: hand;

    /**
     * Important!
     * If z-index is too high the menu hides the contact modal window
     */
    z-index: 888;
}

.hamburger {
    display: none;
    //line-height: 45px;
    //padding: 5px 15px 0px 15px;
    font-size: 1.4em;
    //position: absolute;
    //top: 0px;
    //right: 0;
    @media screen and (max-width: 768px) {
        display: inline;
    }
}

.hamburger i, .cross i {
    font-size: 32px;
    color: white;
}

.logo-and-burger {
    display: none !important;
    padding: unset;

    @media screen and (max-width: 768px) {
        display: inline-flex !important;
    }
}

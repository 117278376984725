#how-it-works {
  .selector {
    width: 375px;

    .line {
      position: absolute;
      width: 3px;
      height: 268px;
      left: 27px;
      top: 159px;
      bottom: 0;
      background-color: #ad94d2;
    }

    ul li.item {
      padding-bottom: 30px;

      .selector-title {
        position: relative;
        display: block;
        padding-bottom: 15px;

        .bullet,
        .caption {
          display: inline-block;
          -webkit-transition: 0.25s;
          -moz-transition: 0.25s;
          -ms-transition: 0.25s;
          -o-transition: 0.25s;
          transition: 0.25s;
        }

        .bullet {
          position: absolute;
          width: 28px;
          height: 28px;
          top: -1px;
          opacity: 0;
        }

        .bullet.bullet-on {
          background: url($hiw-bullet-url) 0 0 no-repeat;
        }

        .bullet.bullet-off {
          opacity: 1;
          background: url($hiw-bullet-url) 0 -31px no-repeat;
        }

        .caption {
          margin-left: 45px;
          font-size: 22px;
          font-weight: 600;
          color: $primary;
          text-transform: uppercase;
          opacity: 0.7;
        }
      }

      .selector-title.active {
        .caption {
          opacity: 1;
        }

        .bullet.bullet-on {
          opacity: 1;
        }

        .bullet.bullet-off {
          opacity: 0;
        }
      }

      .selector-body {
        margin-left: 45px;

        p {
          margin-left: 20px;
          margin-bottom: 10px;
          font-size: 22px;
          line-height: 30px;
          color: #666;
        }

        p a.link {
          color: #555;
          font-weight: 600;
          text-decoration: none !important;
          transition: 0s !important;
        }

        p a.link:hover,
        p a.link.active {
          color: $primary;
          text-decoration: none !important;
        }

        strong {
          color: $primary;
        }
      }
    }
  }

  .scene {
    //position: absolute;
    width: 100%;
    height: 680px;
    //top: 0;
    //margin-top: -40px;
    opacity: 1;
    filter: inherit;

    .card,
    .card-h,
    .device,
    .device-plug,
    .sign,
    .phone,
    .phone-h {
      position: absolute;
      filter: inherit;
      opacity: inherit;
    }

    .card,
    .card-h,
    .device,
    .device-plug,
    .phone-h,
    .phone .body,
    .phone .screens .screen {
      background: url($hiw-sprites-url) center center no-repeat;
    }

    .card {
      display: none; /* VERTICAL CARD NOT USED */
      width: 251px;
      height: 408px;
      left: 50%;
      margin-left: -134px;
      z-index: 3;
      background-position: -779px 0;
    }

    .card-h {
      width: 405px;
      height: 251px;
      top: 240px;
      z-index: 2;
      background-position: -545px -449px;
    }

    .device {
      width: 285px;
      height: 415px;
      left: 50%;
      margin-left: -185px;
      z-index: 5;
      background-position: -1030px 0;
    }

    .device-plug {
      display: none;
      width: 33px;
      height: 71px;
      left: 215px;
      margin-top: 225px;
      z-index: 1;
      background-position: -1030px -300px;
    }

    .sign {
      width: 237px;
      height: 390px;
      left: 338px;
      top: 141px;
      margin-left: -257px;
      outline: none;
      border: 0;
      z-index: 6;
    }

    .phone {
      width: 309px;
      height: 611px;
      left: 50%;
      margin-left: -154px;
      z-index: 2;

      .screens, .body {
        position: absolute;
      }

      .screens {
        overflow: hidden;
        z-index: 2;
        width: 238px;
        height: 421px;
        left: 50%;
        top: 50%;
        margin-left: -115px;
        margin-top: -217px;

        .screen {
          position: absolute;
          width: 238px;
          height: 425px;
          left: 234px;
        }

        .screen1 {
          background-position: -305px 0;
        }

        .screen2 {
          background-position: -541px 0;
        }

        .screen3 {
          background-position: -1305px 0;
        }

        .screen4 {
          background-position: -1533px 0;
        }
      }

      .body {
        margin-left: 5px;
        width: 299px;
        height: 611px;
        background-position: 0 0;
        z-index: 2;
      }
    }

    .phone-h {
      width: 588px;
      height: 280px;
      top: 70px;
      left: 50%;
      margin-left: -274px;
      z-index: 2;
      background-position: 0 -620px;
    }
  }

  /*
   * Alternative SVG image container
   */
  .svg-alternative {
    @media screen and(min-width: 768px) {
      display: none;
    }
  }

  /*
   * How It Works Animation
   */
  .hiw-animation {
    @media screen and(max-width: 768px) {
      display: none;
    }
  }

}
@font-face {
  font-family: 'Gotham';
  src: url('~assets/fonts/Gotham-Book.eot');
  src: url('~assets/fonts/Gotham-Book.eot?#iefix') format('embedded-opentype'),
  url('~assets/fonts/Gotham-Book.woff2') format('woff2'),
  url('~assets/fonts/Gotham-Book.woff') format('woff'),
  url('~assets/fonts/Gotham-Book.ttf') format('truetype'),
  url('~assets/fonts/Gotham-Book.svg#Gotham-Book') format('svg');
  font-weight: normal;
  font-style: normal;
}
